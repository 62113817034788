import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; // Importa Link
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../styles/body.css'
import Swal from 'sweetalert2'

function Body(){

    const images = require.context('../imgs', true);

    useEffect(()=>{
        mostrarAlerta();

    }, [])

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: 'btn btn-outline-info',
        cancelButton: 'btn btn-outline-danger'
        },
        buttonsStyling: false
    })

    const mostrarAlerta = () => {

        swalWithBootstrapButtons.fire({
            title: '<strong>POLITICA DE TRATAMIENTO DE DATOS</strong>',
            icon: 'info',
            iconColor:'darkgreen',
            html:
            'En nuestra página web, valoramos tu privacidad y nos comprometemos a proteger tus datos personales. Al aceptar nuestro tratamiento de datos, nos das tu consentimiento para recopilar, almacenar y utilizar la información proporcionada con el fin de brindarte una experiencia personalizada y mejorar nuestros servicios. ' +
            '<a href="https://www.flipsnack.com/crisrocatalogue/poli-tica-de-tratamiento-de-datos-e-informacio-n-d-s/full-view.html" style="color:darkblue" target="_blank">Nuestra politica</a> ',
            showCloseButton: false,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText:
            'Acepto!',
            cancelButtonText:
            'No Acepto!',
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.cancel){
                window.location = "https://www.google.com/search?q=Desarrollo+y+Servicios+Empresariales";
            }
        })

    }

    


    return (


        <div className="body p-4">
            <div className='body_main_color shadow rounded'>
                <div className='body_main'>
                    <h1 className='title_boddy'> ¿Quiénes somos? </h1>
                    <br />
                    <br />
                    <p className='h4'> Somos un BPO (Business Process Outsourcing) que ofrece servicios de externalización de procesos a todos los sectores económicos, nuestro objetivo principal es encargarnos de la ejecución de tareas y procesos específicos para nuestros clientes, evitando así, que se disponga de recursos innecesarios a estas actividades, logrando que se dirijan al cumplimiento del core de su negocio. En DyS SAS, ofrecemos soluciones personalizadas de outsourcing integral buscando ayudar a nuestros aliados a mejorar su eficiencia, reducir costos y aumentar su productividad.</p>
                </div>
            </div>
            <div>
                <div>
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">PORTAL WEB</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">SERVICIOS</button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">CANALES DE CONTACTO</button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className='card shadow'>
                                <img src={images('./portal.png')} className="card-img-top img-fluid" alt="..." />
                                <div className='card-body'>
                                    <h5 className='card-title h3'>Portal de Autogestión</h5>
                                    <p className='card-text h4 font-weight-normal'>En el portal podrás realizar diferentes trámites como registrar tu hoja de vida, descargar tus comprobantes de nómina, certificado laboral, certificado de ingresos y retenciones y comprobante de seguridad social </p>
                                    <Link to="/portal" className="btn btn-primary">PORTAL WEB</Link>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className='card shadow'>
                                <img src={images('./services.jpg')} className="card-img-top img-fluid" alt="..." />
                                <div className='card-body'>
                                    <h5 className='card-title h3'>Conoce los servicios que ofrecemos</h5>
                                    <Link to="/services" className="btn btn-primary">SERVICIOS</Link>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                            <div className='card shadow'>
                                <img src={images('./contacto.jpg')} className="card-img-top img-fluid" alt="..." />
                                <div className='card-body'>
                                    <h5 className='card-title h3'>Escríbenos un correo</h5>
                                    <p className='card-text h4'>Para conocer nuestros canales de contacto pulsa en el botón de abajo</p>
                                    <Link to="/contactanos" className="btn btn-primary">CONTACTANOS</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
        </div>

    );

}

export default Body;